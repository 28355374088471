.hero_section {
  width: 100%;
  height: 100vh;
  background-image: url("../../Images/hero_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.hero_section2 {
  width: 100%;
  height: 100%;
  padding: 2rem 0;
  background: #1e1e2f;
}

.hero_container {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hc_1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.premint-text {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.hc_1 button {
  font-size: 2rem;
  padding: 10px 20px;
  border: none;
  font-family: "Orbitron", sans-serif;
  cursor: pointer;
  color: black;
  background: #efd93e;
  border-radius: 10px;
}

.hc_1 button:hover {
  color: black;
  background: #efd93e;
}

.hc_1 button:disabled {
  /* color: black; */
  background: #888;
}

.hero_content img {
  width: 300px;
  border-radius: 20px;
  object-fit: contain;
}

.cmng_t {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.cld_anm {
  width: 218px;
  height: 218px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cld_anm img {
  width: 200px !important;
}

.cmng_t button {
  background: #55acee;
  border: none;
  outline: none;
  padding: 15px 20px;
  margin-top: 40px;
  font-size: 2rem;
  border-radius: 10px;
  cursor: pointer;
  color: white;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
    filter: hue-rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    filter: hue-rotate(360deg);
  }
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Banner */

.banner_section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.banner_section img {
  width: 150px;
  object-fit: contain;
}

/* about_section */

.about_section {
  padding: 2rem 0 0 0;
  overflow: hidden;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.abt_banner {
  width: 100%;
  height: 100%;
  margin: 2rem 0;
}

.about_content,
.about_content2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about_section > div h1 {
  font-size: 3rem;
  color: #464b4b;
  font-weight: 900;
  text-shadow: 1px 2px 10px rgb(36 57 79 / 40%);
  text-align: center;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.about_section > p {
  font-size: 1.2rem;
  text-align: center;
  margin: 10px auto;
  color: #ffffff8a;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.8;
  width: 90%;
}

.ab_l {
  width: 50%;
  padding: 46px 40px;
}

.ab_l > div h2 {
  font-size: 2rem;
  font-weight: 600;
  color: white;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.ab_l p {
  text-align: justify;
  margin: 10px 0;
  font-size: 1rem;
  line-height: 1.8;
  color: #ffffff8a;
}

.ab_r {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ab_r > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ab_tr img {
  width: 300px;
}

.ab_r div img {
  text-align: center;
  width: 180px;
  margin-bottom: 10px;
  border-radius: 100%;
}

.glow-earth {
  margin-right: -26px;
}

.about_content2 {
  flex-direction: row-reverse;
}

/* roadmap */

.roadmap {
  letter-spacing: 1px;
  padding: 4rem 0;
  width: 100%;
  height: max-content;
  overflow-x: hidden;
  margin-top: -6px;
}

.roadmap > div h1 {
  font-size: 2rem;
  color: white;
  font-weight: 900;
  text-shadow: 1px 2px 10px rgb(36 57 79 / 40%);
  text-align: center;
}

.roadmap > p {
  width: 80%;
  margin: 20px auto;
  text-align: center;
  line-height: 1.5;
  color: #ffffff8a;
}

.roadmap .container {
  max-width: 650px;
  margin: 3rem auto 0 auto;
}

.roadmap .container p {
  font-weight: 300;
  line-height: 1.5;
  font-size: 14px;
  color: #ffffff8a;
}

.timeline {
  position: relative;
  color: white;
}

.timeline .timeline-container {
  position: relative;
  margin-bottom: 2.5rem;
}

.timeline-container {
  display: flex;
  align-items: center;
}

.timeline-icon {
  width: 100px;
  margin-right: 54px;
}

.timeline .timeline-container .timeline-body {
  background: #27293d;
  border-radius: 3px;
  padding: 20px 20px 15px;
  position: relative;
  box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1);
}
.timeline .timeline-container .timeline-body:before {
  content: "";
  background: inherit;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: -9px;
  transform: rotate(45deg);
  border-radius: 0 0 0 2px;
}
.timeline .timeline-container .timeline-body .timeline-title {
  margin-bottom: 1.4rem;
}
.timeline .timeline-container .timeline-body .timeline-title .badge {
  background: #4f537b;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 900;
}
.timeline .timeline-container .timeline-body .timeline-subtitle {
  font-weight: 300;
  font-style: italic;
  opacity: 0.4;
  margin-top: 16px;
  font-size: 11px;
}

.author {
  font-family: inherit;
  padding: 3em;
  text-align: center;
  width: 100%;
  color: white;
}
.author a:link,
.author a:visited {
  color: white;
}
.author a:link:hover,
.author a:visited:hover {
  text-decoration: none;
}
.author .btn:link,
.author .btn:visited {
  margin-top: 1em;
  text-decoration: none;
  display: inline-block;
  font-family: inherit;
  font-weight: 100;
  color: white;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: black;
  padding: 1.5em 2rem;
  border-radius: 1em;
  transition: 0.5s all;
}
.author .btn:link:hover,
.author .btn:link:focus,
.author .btn:link:active,
.author .btn:visited:hover,
.author .btn:visited:focus,
.author .btn:visited:active {
  background-color: #1a1a1a;
}

@media screen and (max-width: 952px) {
  .hc_1 img {
    width: 250px !important;
  }
  .hero_content img {
    width: 193px;
  }
  .cmng_t button {
    font-size: 1.5rem;
    padding: 11px 15px;
  }
  .banner_section img {
    width: 126px;
  }
}

@media screen and (max-width: 768px) {
  .cld_anm {
    width: 150px;
  }
  .ab_r {
    margin-top: 2rem;
    width: 100%;
  }
  .ab_l {
    width: 100%;
  }
  .banner_section img {
    width: 100px;
  }
  .timeline {
    width: 90%;
    margin: 0 auto;
  }
  .timeline-icon {
    width: max-content;
    margin: 1rem 0;
  }
  .timeline-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 765px) {
  .about_content,
  .about_content2 {
    flex-direction: column-reverse;
  }
  .about_section > p {
    width: 96%;
  }
}

@media screen and (max-width: 700px) {
  .roadmap .container {
    width: 94%;
  }
}

@media screen and (max-width: 652px) {
  .hero_content {
    flex-direction: column;
  }
  .hc_1 {
    margin-top: 11%;
    /* margin-left: 15%; */
    /* align-items: flex-start; */
  }
  .hero_section {
    height: 78vh;
    background-size: 100% 100%;
  }
  .roadmap > p {
    font-size: 0.9rem;
  }
  .banner_section img {
    width: 80px;
  }
  .hero_section {
    height: 64vh;
  }
}

@media screen and (max-width: 552px) {
  .hero_section {
    height: 68vh;
  }
  .hc_1 img {
    width: 221px !important;
  }
}

@media screen and (max-width: 512px) {
  .banner_section {
    justify-content: center;
  }
  .banner_section img {
    width: 60px;
    margin: 0 10px;
  }
}

@media screen and (max-width: 425px) {
  .hero_section {
    height: 54vh;
  }
  .hc_1 button {
    font-size: 1.5rem;
    padding: 7px 17px;
  }
  .about_section > p {
    font-size: 0.9rem;
  }
  .roadmap .container {
    width: 90%;
  }
  .roadmap > p,
  .roadmap .container p {
    font-size: 0.8rem;
  }
  .timeline .timeline-container {
    margin-left: -15px;
  }
  .banner_section img {
    width: 45px;
    margin: 0 10px;
  }
}

@media screen and (max-width: 400px) {
  .about_section > p {
    margin-bottom: 2rem;
    font-size: 0.8rem;
  }
  .ab_l p {
    font-size: 0.8rem;
  }
  .ab_r {
    margin-top: 0rem;
  }
  .ab_r div img {
    width: 150px;
  }
  /* .hc_1 {
    margin-top: 21%;
    margin-left: 15%;
    align-items: flex-start;
  } */
}

@media screen and (max-width: 340px) {
  .banner_section img {
    width: 38px;
    margin: 0 10px;
  }
}
