header {
  width: 100%;
  height: max-content;
  padding: 1.2rem 0;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 999;
}

.mob_menu,
.menu {
  display: none;
}

.active {
  display: none;
  background-color: #000000b8;
}

.h_container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hl,
.desk {
  display: flex;
  align-items: center;
}

.nav_logo img {
  width: 40px;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.title-text {
  position: relative;
}

.cyber-title-text {
  margin: 0;
  color: #ffee08;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-family: "Orbitron";
  font-weight: 900;
  letter-spacing: -1px;
  padding: 2rem;
  white-space: nowrap;
}

.cyber-title-text:after,
.cyber-title-text:before {
  position: absolute;
  content: attr(data-text);
  padding: 2rem;
  top: 0;
  left: 0;
  color: #ffee08;
  overflow: hidden;
  white-space: nowrap;
}

.cyber-title-text:before {
  left: 3px;
  text-shadow: -3px 2px #87cefa;
  animation: glitch-1 3s ease-in infinite reverse;
}

.cyber-title-text:after {
  left: -3px;
  text-shadow: -5px 2px #fff;
  animation: glitch-2 3s ease-in-out infinite reverse;
}

@keyframes glitch-1 {
  0% {
    clip: rect(132px, auto, 101px, 30px);
  }
  5% {
    clip: rect(17px, auto, 94px, 30px);
  }
  10% {
    clip: rect(40px, auto, 66px, 30px);
  }
  15% {
    clip: rect(87px, auto, 82px, 30px);
  }
  20% {
    clip: rect(137px, auto, 61px, 30px);
  }
  25% {
    clip: rect(34px, auto, 14px, 30px);
  }
  30% {
    clip: rect(133px, auto, 74px, 30px);
  }
  35% {
    clip: rect(76px, auto, 107px, 30px);
  }
  40% {
    clip: rect(59px, auto, 130px, 30px);
  }
  45% {
    clip: rect(29px, auto, 84px, 30px);
  }
  50% {
    clip: rect(22px, auto, 67px, 30px);
  }
  55% {
    clip: rect(67px, auto, 62px, 30px);
  }
  60% {
    clip: rect(10px, auto, 105px, 30px);
  }
  65% {
    clip: rect(78px, auto, 115px, 30px);
  }
  70% {
    clip: rect(105px, auto, 13px, 30px);
  }
  75% {
    clip: rect(15px, auto, 75px, 30px);
  }
  80% {
    clip: rect(66px, auto, 39px, 30px);
  }
  85% {
    clip: rect(133px, auto, 73px, 30px);
  }
  90% {
    clip: rect(36px, auto, 128px, 30px);
  }
  95% {
    clip: rect(68px, auto, 103px, 30px);
  }
  to {
    clip: rect(14px, auto, 100px, 30px);
  }
}

@keyframes glitch-2 {
  0% {
    clip: rect(129px, auto, 36px, 30px);
  }
  5% {
    clip: rect(36px, auto, 4px, 30px);
  }
  10% {
    clip: rect(85px, auto, 66px, 30px);
  }
  15% {
    clip: rect(91px, auto, 91px, 30px);
  }
  20% {
    clip: rect(148px, auto, 138px, 30px);
  }
  25% {
    clip: rect(38px, auto, 122px, 30px);
  }
  30% {
    clip: rect(69px, auto, 54px, 30px);
  }
  35% {
    clip: rect(98px, auto, 71px, 30px);
  }
  40% {
    clip: rect(146px, auto, 34px, 30px);
  }
  45% {
    clip: rect(134px, auto, 43px, 30px);
  }
  50% {
    clip: rect(102px, auto, 80px, 30px);
  }
  55% {
    clip: rect(119px, auto, 44px, 30px);
  }
  60% {
    clip: rect(106px, auto, 99px, 30px);
  }
  65% {
    clip: rect(141px, auto, 74px, 30px);
  }
  70% {
    clip: rect(20px, auto, 78px, 30px);
  }
  75% {
    clip: rect(133px, auto, 79px, 30px);
  }
  80% {
    clip: rect(78px, auto, 52px, 30px);
  }
  85% {
    clip: rect(35px, auto, 39px, 30px);
  }
  90% {
    clip: rect(67px, auto, 70px, 30px);
  }
  95% {
    clip: rect(71px, auto, 103px, 30px);
  }
  to {
    clip: rect(83px, auto, 40px, 30px);
  }
}
@keyframes color-change {
  0% {
    color: #6f9861;
  }
  50% {
    color: #ff0000a6;
  }
  100% {
    color: #efef9a;
  }
}

.nav_links a {
  color: white;
  margin: 0 10px;
  font-size: 1.2rem;
  font-weight: 600;
  transition: 0.8s ease;
}

.social_icons {
  display: flex;
  margin: 0 31px;
  align-items: center;
  width: 140px;
  justify-content: space-between;
}

.social_icons img {
  object-fit: contain;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}

.social_icons img:hover {
  animation-name: rubberBand;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

.connect_btn {
  border: none;
  color: white;
  padding: 14px 10px;
  background: transparent;
  font-weight: 700;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Orbitron", sans-serif !important;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}

@media screen and (max-width: 992px) {
  .active {
    display: unset;
  }
  .desk {
    display: none;
  }
  header {
    align-items: flex-start;
  }
  .nav_title h2 {
    display: none;
  }
  .cyber-title-text {
    display: none;
  }
  .nav_links {
    display: flex;
    flex-direction: column;
    line-height: 50px;
  }
  .nav_links a {
    font-size: 1.5rem;
  }
  .menu {
    display: unset;
    position: absolute;
    right: 2rem;
    top: 37px;
    z-index: 999;
  }
  .mob_menu {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    width: 350px;
    align-items: center;
    transition: all 0.5s ease 0s;
    justify-content: center;
  }
  .mb_hl {
    display: flex;
    margin-top: 2rem;
  }
  .social_icons {
    margin: 65px 31px;
  }
  .hr_2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 425px) {
  .hero_section {
    height: 45vh;
  }
  .nav_logo img {
    width: 30px;
    position: absolute;
    top: 18px;
    left: 15px;
  }
  header {
    padding: 10px 0;
  }
  .mb_hl {
    padding-left: 2rem;
  }
  .menu {
    right: 1.4rem;
    top: 23px;
    width: 28px;
  }
  .mob_menu {
    width: 100%;
    align-items: flex-start;
  }
}

@media screen and (max-width: 400px) {
  .nav_title h2 {
    font-size: 1.2rem;
  }
}
