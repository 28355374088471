@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Orbitron", sans-serif !important;
}

a {
  color: unset;
  text-decoration: none;
}

h1,
h2,
p {
  margin: 0;
}
