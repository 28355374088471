.footer {
  width: 100%;
  height: 100%;
  background-color: #202020;
  padding: 25px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer h5 {
  font-size: 0.9rem;
  color: white;
  text-align: center;
  line-height: 1.6;
  /* line-height: 1.9; */
}

.footer a {
  color: #ffea2a;
  font-weight: 600;
}

@media screen and (max-width:425px) {
    .footer h5 {
        font-size: 0.8rem;
    }
}

@media screen and (max-width:375px) {
    .footer h5 {
        font-size: 0.7rem;
    }
}